import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import MapsConfig from './MapConfig';
 
const MapSelectionModal = ({ show, handleClose, selectedMaps, setSelectedMaps }) => {

  const mapOptions = MapsConfig;
  
  const handleSelectChange = (index, value) => {
    const updatedMaps = [...selectedMaps];
    updatedMaps[index] = mapOptions.find(option => option.url === value);
    // console.log(updatedMaps, updatedMaps[index])
    setSelectedMaps(updatedMaps);
  };

  const limitedSelectedMaps = selectedMaps.slice(0, 4);
  
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Maps</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {limitedSelectedMaps.map((map, index) => (
            <Form.Group key={index}>
              <Form.Label>Map {index + 1}</Form.Label>
              <Form.Control
                as="select"
                value={map.url}
                onChange={(e) => handleSelectChange(index, e.target.value)}
              >
                {mapOptions.map((option, idx) => (
                  <option key={idx} value={option.url}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MapSelectionModal;
