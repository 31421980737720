// Navbar.js
import React, { useState } from 'react';
import Logo from './Logo';
import MapSelectionModal from './MapSelectionModal';
import { Button } from 'react-bootstrap';

const Navbar2 = ({ mapsData, setMapsData }) => {
    const [showModal, setShowModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const toggleNavbar = () =>  setIsOpen(!isOpen);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <button 
                className="navbar-toggler" 
                type="button" 
                onClick={toggleNavbar} 
                aria-controls="navbarTogglerDemo03" 
                aria-expanded={isOpen} 
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand" href="/"><Logo/></a>
            <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarTogglerDemo03">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    {/* <li className="nav-item active">
                        <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/routing">Link</a>
                    </li> */}
                </ul>
                <form className="form-inline my-2 my-lg-0">
                    <Button className="maps-btn" variant="outline-warning" role="link" style={{border: "2px gray solid"}}>
                        <a href='/' target="_self" style={{ textDecoration: 'none'}} color='black'>Home</a>   
                    </Button>
                </form>
            </div>
        </nav>
    );
};

export default Navbar2;
