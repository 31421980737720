import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polyline, useMap, Marker, LayerGroup, LayersControl } from 'react-leaflet';
import "../routingPage.css"
import "leaflet/dist/leaflet.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { decode } from '@here/flexpolyline';
import { JsonTable } from 'json-react-table'
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Navbar2 from '../Navbar2';
import MapsConfig from '../MapConfig';
import { Icon, polyline } from 'leaflet';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { debounce } from 'lodash';


// functional component to display two maps side-by-side with a synchronized zoom and
// polyline plotting as a result of routing API calls
function RoutingComparator() {

    const userLocationMarker = import("../assets/mylocation.png");
    const placeholder = import("../assets/placeholder.png");
    const [sliderValue, setSliderValue] = useState(50);
    const [origin, setOrigin] = useState("");
    const [destination, setDestination] = useState("");
    const [originData, setOriginData] = useState([]);
    const [destinationData, setDestinationData] = useState([]);
    const [userLocation, setUserLocation ] = useState([]);
    const [originLocation, setOriginLocation] = useState([]);
    const [destinationLocation, setDestinationLocation] = useState([]);
    const [lockLat, setLockLat] = useState("");
    const [lockLon, setLockLon] = useState("");
    const [locationIcon, setLocationIcon] = useState(false);
    const [mapsData, setMapsData] = useState(MapsConfig);
    const [orbInstructions, setOrbInstructions] = useState([]);
    const [valInstructions, setValInstructions] = useState([]);
    const [ttETA, setTomtomETA] = useState("");
    const [hereETA, setHereETA] = useState("");
    const [valhallaETA, setValhallaETA] = useState("");
    const [ttRouteShape, setTTRouteShape] = useState([]);
    const [hereRouteShape, setHereRouteShape] = useState([]);
    const [valhallaShape, setValhallaShape] = useState([]);
    const [position, setPosition] = useState(null);
    const [zoomLevel, setZoom] = useState(10);
    const [center, setCenter] = useState([-25.878078, 28.168741]); 
    const [ttRoute, setTomtomRoute] = useState([]);
    const [hereRoute, setHereRoute] = useState([]);
    const [valhallaRoute, setValhallaRoute] = useState([]);
    const [routingSuccess, setRoutingSuccess] = useState(false);
    const [mapBounds, setMapBounds] = useState([[-28.48322,24.676997], [-25.878078, 28.168741]]);
    const colorOptions = { color: 'blue', weight: '4' };
    const { BaseLayer } = LayersControl;
    const [mapOptions, setMapOptions] = useState([
            {
                name: 'Orbis',
                url: 'https://api.tomtom.com/maps/orbis/map-display/tile/{z}/{x}/{y}.png?apiVersion=1&key=qu6eef0BAgydDTJAiZWaCjWRTEhFC7ky&style=street-light&tileSize=512&view=Unified&language=en',
                attribution: 'Map data © 2023 TomTom Orbis',
                routeShape: [],
                center: []
            },
            {
                name: 'Here Maps',
                url: 'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=NkEyGwUMIhGY9V1bUTDlr_kyQgNILFMZgAiULvHFLIo',
                attribution: 'Map data © 2023 HERE',
                routeShape: [],
                center: []
            },
            {
                name: 'OSM-Valhalla',
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution: 'Map data © 2023 OpenStreetMap',
                routeShape: [],
                center: []
            },
            {
                name: 'Esri',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/Basemap/MapServer/tile/{z}/{y}/{x}',
                attribution: 'Map data © 2023 OpenStreetMap',
                routeShape: [],
                center: []
            }
    ]);
        
    // Define the custom icon
    const originIcon = new Icon({
        iconUrl: "placeholder.png", // Path to your custom icon image
        iconSize: [30, 30], // size of the icon
        iconAnchor: [20, 10], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -60] // point from which the popup should open relative to the iconAnchor
    });

    const destinationIcon = new Icon({
        iconUrl: "end.png", // Path to your custom icon image
        iconSize: [30, 30], // size of the icon
        iconAnchor: [20, 10], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -60] // point from which the popup should open relative to the iconAnchor
    });

    // zoom slider scale definitions
    const marks = [
        {
            value: 0,
            label: 'zoom level 0',
        },
        {
            value: 10,
            label: '2',
        },
        {
            value: 20,
            label: '4',
        },
        {
            value: 30,
            label: '6',
        },
        {
            value: 40,
            label: '8',
        },
        {
            value: 50,
            label: '10',
        },
        {
            value: 60,
            label: '12',
        },
        {
            value: 70,
            label: '14',
        },
        {
            value: 80,
            label: '16',
        },
        {
            value: 90,
            label: '18',
        },
        {
            value: 100,
            label: '20',
        }
    ];

    useEffect(() => {

        if(routingSuccess){
            setHereRouteShape(mapOptions[1].routeShape);
            setTTRouteShape(mapOptions[0].routeShape);
            setValhallaShape(mapOptions[2].routeShape);
            // setMapBounds(mapOptions[0].routeShape);
        }

    }, [mapOptions, routingSuccess])

    // This is adapted from the implementation in Project-OSRM
    // https://github.com/DennisOSRM/Project-OSRM-Web/blob/master/WebContent/routing/OSRM.RoutingGeometry.js
    const decodePolyline = (str, precision) => {
    var index = 0,
        lat = 0,
        lng = 0,
        coordinates = [],
        shift = 0,
        result = 0,
        byte = null,
        latitude_change,
        longitude_change,
        factor = Math.pow(10, precision || 6);

    // Coordinates have variable length when encoded, so just keep
    // track of whether we've hit the end of the string. In each
    // loop iteration, a single coordinate is decoded.
    while (index < str.length) {

        // Reset shift, result, and byte
        byte = null;
        shift = 0;
        result = 0;

        do {
            byte = str.charCodeAt(index++) - 63;
            result |= (byte & 0x1f) << shift;
            shift += 5;
        } while (byte >= 0x20);

        latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

        shift = result = 0;

        do {
            byte = str.charCodeAt(index++) - 63;
            result |= (byte & 0x1f) << shift;
            shift += 5;
        } while (byte >= 0x20);

        longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

        lat += latitude_change;
        lng += longitude_change;

        coordinates.push([lat / factor, lng / factor]);
    }

    return coordinates;
};

    const handleZoomUpdate = (e) => {

        let newZoom = e.target.value;
        
        if(newZoom <= 100) {
            setZoom(newZoom/5)
        }
    }

    // const SyncMapEvent = () => {
    //     const map = useMap();
    
    //     // const debouncedSetViewState = useRef(debounce((lat, lng, zoom) => {
    //     //     map.setView({ lat, lng, zoom });
    //     // }, 100)).current;
      
    //     useEffect(() => {
    //         const onMove = () => {
    //             const center = map.getCenter();
    //             const zoom = map.getZoom();
    //             // debouncedSetViewState(center.lat, center.lng, zoom);
    //         };
            
    //         map.on('move', onMove);
    //         map.on('zoom', onMove);
        
    //         return () => {
    //             map.off('move', onMove);
    //             map.off('zoom', onMove);
    //         };
    //     }, [map]);
      
    //     useEffect(() => {
    //         map.setView(center, zoomLevel, { animate: true });
    //     }, [map]);
      
    //     return null;
    // };

    // this method is called when the 'get route' button is clicked
	const checkRoute = () => {

		// if the orgin and destination state values (these are bound to the loation inputs on the route page) 
		// are not of length 0...
		if (origin.length > 0 && destination.length > 0) {

            //update state of origin and destination locations
            setOriginLocation([originData[0].position.lat, originData[0].position.lon]);
            setDestinationLocation([destinationData[0].position.lat, destinationData[0].position.lon]);

			// set the start and end positions (using the coords)
			var start = originData[0].position;
			var end = destinationData[0].position;
			// and then use these values to call the routing method
			calculateRoutes(start, end);
		}
        else {
            alert("Please check your origin and destination inputs and press ENTER after each input.");
        }
	}

    const flattenInstructions = (sourceData, sourceName) => {

        let flatInstructions = [];

        if(sourceName === "orbis") {
            let combinedInstructions = sourceData.routes[0].guidance.instructions;

            combinedInstructions.map((instructionGroup, idx) => (
                flatInstructions.push({step: idx, message: instructionGroup.message})

            ));

            setOrbInstructions(flatInstructions) 

        }else {
            let combinedInstructions = sourceData.trip.legs[0].maneuvers;

            combinedInstructions.map((instructionGroup, idx) => (

                flatInstructions.push({step: idx, message: instructionGroup.verbal_pre_transition_instruction})
            ));

            setValInstructions(flatInstructions)       

        }
    }

    // routing method
	// this function will send requests to the api of the selected maps, and
    // change the state of the related/affected variables in order to trigger a re-render
	const calculateRoutes = async (start, end) => {


		if (start !== undefined && end !== undefined) {

			try {
                // const ttRouteRequest = fetch("https://api.tomtom.com/routing/1/calculateRoute/"+start.lat+","+start.lon+":"+end.lat+","+end.lon+"/json?departAt=now&routeType=fastest&travelMode=truck&vehicleMaxSpeed=80&vehicleWeight=36&vehicleAxleWeight=11&vehicleLength=14&vehicleWidth=2.5&vehicleHeight=4.5&key=eYcMZC3yQCZRHa0fzBhRgQQPeGi3J4OS")

                const ttRouteRequest = fetch("https://api.tomtom.com/routing/1/calculateRoute/"+start.lat+","+start.lon+":"+end.lat+","+end.lon+"/json?instructionsType=text&language=english&computeBestOrder=true&routeRepresentation=polyline&computeTravelTimeFor=all&routeType=fastest&traffic=true&avoid=unpavedRoads&travelMode=car&vehicleEngineType=combustion&key=eYcMZC3yQCZRHa0fzBhRgQQPeGi3J4OS");
                const hereRoutelineRequest = fetch("https://router.hereapi.com/v8/routes?transportMode=car&origin="+start.lat+","+start.lon+"&destination="+end.lat+","+end.lon+"&return=polyline&apikey=NkEyGwUMIhGY9V1bUTDlr_kyQgNILFMZgAiULvHFLIo");
                const hereRouteETARequest = fetch("https://router.hereapi.com/v8/routes?transportMode=car&origin="+start.lat+","+start.lon+"&destination="+end.lat+","+end.lon+"&return=summary&apikey=NkEyGwUMIhGY9V1bUTDlr_kyQgNILFMZgAiULvHFLIo");
                const valhallaRequest = fetch(`
                    https://valhalla1.openstreetmap.de/route?json={"costing":"auto","costing_options":{"auto":{"maneuver_penalty":5,"country_crossing_penalty":0,
                    "country_crossing_cost":600,"width":1.6,"height":1.9,"use_highways":1,"use_tolls":1,"use_ferry":1,"ferry_cost":300,"use_living_streets":0.5,
                    "use_tracks":0,"private_access_penalty":450,"ignore_closures":false,"ignore_restrictions":false,"ignore_access":false,"closure_factor":9,
                    "service_penalty":15,"service_factor":1,"exclude_unpaved":1,"shortest":false,"exclude_cash_only_tolls":false,"top_speed":140,"fixed_speed":0,
                    "toll_booth_penalty":0,"toll_booth_cost":15,"gate_penalty":300,"gate_cost":30,"include_hov2":false,"include_hov3":false,"include_hot":false,
                    "disable_hierarchy_pruning":false}},"exclude_polygons":[],
                    "locations":[{"lon":${start.lon},"lat":${start.lat},"type":"break"},{"lon":${end.lon},"lat":${end.lat},"type":"break"}],
                    "units":"kilometers","alternates":0,"id":"valhalla_directions"}
                    `)
    
                let ttRouteResponse = await ttRouteRequest;
                let hereRouteResponse = await hereRoutelineRequest;
                let hereRouteETAResponse = await hereRouteETARequest;
                let valhallaRouteResponse = await valhallaRequest;
    
                let ttRouteData = await ttRouteResponse.json();
                setTomtomRoute(ttRouteData)
                let hereRouteData = await hereRouteResponse.json();
                let hereETAData = await hereRouteETAResponse.json();
                setHereRoute(hereETAData);
                let valhallaData = await valhallaRouteResponse.json();
                setValhallaRoute(valhallaData);

                // call function to create a flattened route guidance/instructions list
                flattenInstructions(ttRouteData, "orbis");
                flattenInstructions(valhallaData, "valhalla");
                    
                let hereRouteLength = hereETAData.routes[0].sections[0].summary.length;
                let valhallaRouteLength = valhallaData.trip.summary.length;

                let hereEncodedShape = hereRouteData.routes[0].sections[0].polyline;
                let valEncodedShape = valhallaData.trip.legs[0].shape
    
                let ttTravelDistance = (ttRouteData.routes[0].summary.lengthInMeters / 1000).toFixed(1);
                let hereTravelDistance = (hereRouteLength / 1000).toFixed(1);
                let valTravelDistance = (valhallaRouteLength.toFixed(1));
                    
                let ttTravelTime = ttRouteData.routes[0].summary.travelTimeInSeconds;
                let hereTravelTime = hereETAData.routes[0].sections[0].summary.duration;
                let valTravelTime = valhallaData.trip.summary.time;
    
                var ttSeconds = parseInt(ttTravelTime, 10);
                var hereSeconds = parseInt(hereTravelTime, 10);
                var valSeconds = parseInt(valTravelTime, 10);
    
                var ttHrs = Math.floor(ttSeconds / 3600);
                ttSeconds  -= ttHrs*3600;
                var ttMnts = Math.floor(ttSeconds / 60);
                ttSeconds  -= ttMnts*60;
    
                var hereHrs = Math.floor(hereSeconds / 3600);
                hereSeconds  -= hereHrs*3600;
                var hereMnts = Math.floor(hereSeconds / 60);
                hereSeconds  -= hereMnts*60;

                var valHrs = Math.floor(valSeconds / 3600);
                valSeconds  -= valHrs*3600;
                var valMnts = Math.floor(valSeconds / 60);
                valSeconds  -= valMnts*60;
    
                if(ttHrs===0){
                    setTomtomETA(ttTravelDistance+"KM    |    "+ttMnts+"min");
                    
                }else{
                    
                    setTomtomETA(ttTravelDistance+"KM    |    "+ttHrs+"hrs: "+ttMnts+"min")				
                }
    
                if(hereHrs===0){
                    setHereETA(hereTravelDistance+"KM    |    "+hereMnts+"min");
                    
                }else{
                    
                    setHereETA(hereTravelDistance+"KM    |    "+hereHrs+"hrs: "+hereMnts+"min")				
                }

                if(valHrs===0){
                    setValhallaETA(valTravelDistance+"KM    |    "+valMnts+"min");
                    
                }else{
                    
                    setValhallaETA(valTravelDistance+"KM    |    "+valHrs+"hrs: "+valMnts+"min")				
                }
    
                var points_data = ttRouteData.routes[0].legs[0].points;
    
                if(points_data !== undefined && hereEncodedShape !== undefined && valEncodedShape !== undefined) {
    
                    let decodedHereShape = decode(hereEncodedShape);
                    let decodedValShape = decodePolyline(valEncodedShape);
    
                    let latlonList = [];
                    
                    points_data.forEach(xy => {
                        latlonList.push([parseFloat(xy.latitude), parseFloat(xy.longitude)])
                    });

    
                    // get mid coord on route and set as center
                    let ttLineMidCoord = getMidPoint(latlonList);
                    let hereLineMidCoord = getMidPoint(decodedHereShape.polyline);
    
                    setMapOptions([{
                            name: 'Orbis',
                            url: 'https://api.tomtom.com/maps/orbis/map-display/tile/{z}/{x}/{y}.png?apiVersion=1&key=qu6eef0BAgydDTJAiZWaCjWRTEhFC7ky&style=street-light&tileSize=512&view=Unified&language=en',
                            attribution: 'Map data © 2023 TomTom Orbis',
                            routeShape: latlonList,
                            center: ttLineMidCoord
                        },
                        {
                            name: 'Here Maps',
                            url: 'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=NkEyGwUMIhGY9V1bUTDlr_kyQgNILFMZgAiULvHFLIo',
                            attribution: 'Map data © 2023 HERE',
                            routeShape: decodedHereShape.polyline,
                            center: hereLineMidCoord
                        },
                        {
                            name: 'OSM-Valhalla',
                            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                            attribution: 'Map data © 2023 OpenStreetMap',
                            routeShape: decodedValShape,
                            center: hereLineMidCoord
                        },
                        {
                            name: 'Esri',
                            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/Basemap/MapServer/tile/{z}/{y}/{x}',
                            attribution: 'Map data © 2023 OpenStreetMap',
                            routeShape: decodedValShape,
                            center: hereLineMidCoord
                        }
                    ]);
                    
                    setRoutingSuccess(true);
                    setCenter(hereLineMidCoord[0],hereLineMidCoord[1]);
                    // setZoom(10)
                    setMapBounds([hereLineMidCoord[0],hereLineMidCoord[-1]]);


                }
                else{
                    alert("Could not one or more routes.");
                }
            } catch (error) {
                console.error(error)
            }
		}
	}

    const DebouncedSetViewState = (newCenter, newZoom) => {
        let debouncedFunc = useRef(debounce((newCenter, newZoom) => {
            setCenter(newCenter)
            setZoom(newZoom)
        }), 100).current

        debouncedFunc()
    }

    const ChangeView = ({ newCenter, newZoom }) => {
        const map = useMap();

        if(newCenter === undefined && newZoom === undefined) {

            let defaultCenter = center

            map.setView(defaultCenter, 10);
            return null;
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        // useEffect(() => {
        //     const onMove = () => {
        //         map.setView(newCenter, newZoom, { animate: true });
        //         return null;
        //     };
            
        //     map.on('move', onMove);
        //     map.on('zoom', onMove);
        
        //     return () => {
        //         map.off('move', onMove);
        //         map.off('zoom', onMove);
        //         return null;
        //     };
        // }, [map, newCenter, newZoom]);

        // return null;
        map.setView(newCenter, newZoom, { animate: true });
        return null;

    }

    const getMidPoint = (coordList) => {
        let midCoord = [];
        let midIndex = Math.floor(coordList.length / 2);

        midCoord.push(coordList[midIndex]);
        
        return midCoord;
    }

    const resetRouting = () => {

        setMapOptions([
            {
                name: 'Orbis',
                url: 'https://api.tomtom.com/maps/orbis/map-display/tile/{z}/{x}/{y}.png?apiVersion=1&key=qu6eef0BAgydDTJAiZWaCjWRTEhFC7ky&style=street-light&tileSize=512&view=Unified&language=en',
                attribution: 'Map data © 2023 TomTom Orbis',
                routeShape: [],
                center: []
            },
            {
                name: 'Here Maps',
                url: 'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=NkEyGwUMIhGY9V1bUTDlr_kyQgNILFMZgAiULvHFLIo',
                attribution: 'Map data © 2023 HERE',
                routeShape: [],
                center: []
            },
            {
                name: 'OSM-Valhalla',
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution: 'Map data © 2023 OpenStreetMap',
                routeShape: [],
                center: []
            },
            {
                name: 'Esri',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/Basemap/MapServer/tile/{z}/{y}/{x}',
                attribution: 'Map data © 2023 OpenStreetMap',
                routeShape: [],
                center: []
            }
        ]);

        setOrigin("");
        setDestination("");
        document.getElementById("originInput").value = "";
        document.getElementById("destinationInput").value = "";
        document.getElementById("originInput").focus();
        setHereETA("");
        setTomtomETA("");
        setTTRouteShape([]);
        setHereRouteShape([]);
        setValhallaShape([]);
        setTomtomRoute([]);
        setHereRoute([]);
        setDestinationLocation([]);
        setOriginLocation([]);
        setZoom(10);
        setSliderValue(50);
        setCenter([-25.878078, 28.168741]);
        setMapBounds([[-28.48322,24.676997], [-25.878078, 28.168741]]);
    }

    const originSearch = (e) => {
        if(e.keyCode === 13) {
            var placename1 = e.target.value;
            setOrigin(placename1);

            if (placename1.length > 5) {
                fetch("https://api.tomtom.com/search/2/search/"+placename1+".json?typeahead=true&limit=6&countrySet=zaf&key=eYcMZC3yQCZRHa0fzBhRgQQPeGi3J4OS", {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                .then(res => res.json())
                .then((data) => {
                    setOriginData(data.results)
                });
            }
        }
	}

    const destinationSearch = (e) => {
        if(e.keyCode === 13) {
            var placename2 = e.target.value;
            setDestination(placename2);

            if (placename2.length > 5) {
                fetch("https://api.tomtom.com/search/2/search/"+placename2+".json?typeahead=true&limit=6&countrySet=zaf&key=eYcMZC3yQCZRHa0fzBhRgQQPeGi3J4OS", {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                .then(res => res.json())
                .then((data) => {
                    setDestinationData(data.results)
                });
            }
        }
	}
      
    function valuetext(value) {
        return `${value}`;
    }


    return (
        <div style={{height:"90vh"}}>
            {/* <h3 className='routingHeading' >Routing Comparator</h3> */}
            <Navbar2 mapsData={mapsData} setMapsData={setMapsData} />
            <br/>
            <div className='routingContainer'>
                <div className='flex-container' style={{display: "inline-flex", paddingLeft: "1vw"}}>
                    <FloatingLabel
                        label="origin address"
                        className="mb-3"
                    >
                        <Form.Control 
                            id="originInput"
                            type="text"
                            name="originSearch"
                            list="originText"
                            placeholder='O.R Tambo International Airport' 
                            onKeyDown={originSearch}
                        />
                    </FloatingLabel>
                   
                    <datalist id="originText">
                        {originData.map((destinationItem, idx) => (
                            
                            <option key={idx} value={destinationItem.address.freeformAddress} />
                            
                        ))}		
                    </datalist>
                    <FloatingLabel
                        label="destination address"
                        className="mb-3"
                    >
                        <Form.Control 
                            id="destinationInput"
                            type="text"
                            list="destinationText"
                            name="destinationText"
                            placeholder="20 Woodlands Drive, Woodlands"
                            onKeyDown={destinationSearch}
                        />
                    </FloatingLabel>
                   
                    <datalist id="destinationText">
                        {destinationData.map((destinationItem, idx) => (
                            
                            <option key={idx} value={destinationItem.address.freeformAddress} />
                            
                        ))}		
                    </datalist>
                    <Button 
                        type="submit" 
                        id="reqRouteBtn" 
                        variant="outline-success"
                        style={{
                            height: '40px', 
                            width: '15vw', 
                            padding: '4px', 
                            marginLeft: '50px', 
                            marginRight: '20px',
                            marginTop: '10px', 
                            borderWidth: '2px',
                            textDecoration: 'none'
                        }}
                        onClick={checkRoute}
                    >
                        Submit
                    </Button>
                    
                    <Button 
                        type="reset" 
                        id="resetRoutingBtn" 
                        variant="outline-danger"
                        style={{
                            height: '40px', 
                            width: '15vw', 
                            padding: '4px', 
                            borderWidth: '2px',
                            marginTop: '10px', 
                            textDecoration: 'none'
                        }}
                        onClick={resetRouting}
                    >
                        Reset
                    </Button>
                </div>
            </div>
            {/* iteratively render maps into a grid  */}
            <Box sx={{ width: '50vw', margin:"0 auto" }}>
                    <Slider
                        aria-label="Custom marks"
                        defaultValue={sliderValue}
                        getAriaValueText={valuetext}
                        step={2}
                        onChange={handleZoomUpdate}
                        valueLabelDisplay="off"
                        marks={marks}
                        id="zoomSlider"
                    />
                </Box>
            <div className="grid-maps">
                
                {mapOptions.map((map, idx) => (
                    <div key={Math.floor(Math.random() * 11001)+idx} className="map">
                        
                        <MapContainer 
                            // bounds={mapBounds} 
                            center={center} 
                            zoom={zoomLevel} 
                            scrollWheelZoom={true}
                            style={{ height: '100%', width: '100%' }}
                            zoomAnimation
                            key={Math.floor(Math.random() * 11002)+idx}
                            zoomControl={false}
                        >                            
                            <ChangeView newCenter={center} newZoom={zoomLevel} />
                            {/* <SyncMapEvent /> */}
                            {/* <DebouncedSetViewState newCenter={center} newZoom={zoomLevel} /> */}

                            <div 
                                key={Math.floor(Math.random() * 11003)+idx} 
                                className='map-title-box'
                            >
                                <h6 className='map-title-routing' key={Math.floor(Math.random() * 11002)+idx}>{map.name}</h6>
                            </div>
                            {ttETA.length > 0 && hereETA.length > 0 && valhallaETA.length > 0 ? 
                                <div key={Math.floor(Math.random() * 11004)+idx}
                                    className='map-eta-box' 
                                    hidden={map.name === "Esri" ? true : false}
                                >
                                    <h6 className='map-eta'>
                                        {map.name === "Orbis" ? ttETA : map.name === "Here Maps" ? hereETA : map.name === "OSM-Valhalla" ? valhallaETA : null}
                                    </h6>
                                </div>
                                
                                :
                                <div 
                                    key={Math.floor(Math.random() * 1152)+idx} 
                                    className='map-eta-box' 
                                    hidden={map.name === "Esri" ? true : false}
                                >
                                    <h6 className='map-eta' >
                                        ---- KM | ---- min
                                    </h6>
                                </div>
                            }
                            {
                                map.routeShape.length > 0 ? (
                                    <Polyline 
                                        pathOptions={colorOptions} 
                                        positions={map.routeShape}
                                        key={Math.floor(Math.random() * 1803)+idx}
                                        smoothFactor={1}
                                        // ref={"polylineRef"}
                                        // className='polylineRef'
                                    />
                                )
                                :
                                <></>
						    }
                            {
                                originLocation.length === 0 ? (
                                    <></>  
                                )
                                :
                                (
                                <Marker 
                                    key={Math.floor(Math.random() * 1104)+idx} 
                                    position={originLocation} icon={originIcon}
                                />
                                )
						    }
                            {
                                destinationLocation.length === 0 ? (
                                    <></>
                                )
                                :
                                <Marker 
                                    key={Math.floor(Math.random() * 1195)+idx} 
                                    position={destinationLocation} icon={destinationIcon}
                                />
                            }
                            <LayersControl position="topleft" key={Math.floor(Math.random() * 12002)+idx}>
                               { map.name === "Esri" ? 
                                    (   
                                        <>
                                        <BaseLayer checked name={map.name} key={Math.floor(Math.random() * 21002)+idx}>
                                            
                                            <TileLayer url={map.url} attribution={map.attribution} tileSize={256} />
                                        </BaseLayer>
                                        <LayersControl.Overlay checked name="Transparent" key={Math.floor(Math.random() * 1132)+idx}>
                                            <LayerGroup>
                                                <TileLayer
                                                    // attribution='&copy; <a href="https://geoint.africa/">GeoInt Map</a> contributors'
                                                    url='https://api.maps.geoint.africa/v1/tile/{x}/{y}/{z}.png?settings=zaf-decarta_tt_transparent_mdpi'
                                                    style={{zIndex: 2}}
                                                >
                                                </TileLayer>

                                            </LayerGroup>
                                        </LayersControl.Overlay>
                                        </>
                                    ) 
                                    :
                                    (
                                        <BaseLayer checked name={map.name} key={Math.floor(Math.random() * 1142)+idx}>
                                            
                                            <TileLayer url={map.url} attribution={map.attribution} tileSize={256} />
                                        </BaseLayer>
                                    )
                                }
                            </LayersControl>
                        </MapContainer>
                    </div>
                ))}
            </div>
            <br/>
            <hr/>
            <br/>
            <Accordion defaultActiveKey="0" style={{width: "98vw", marginLeft:"12px"}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h4 style={{marginLeft: '5px'}}>
                            Tomtom Response Data
                        </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                    {
                        ttETA.length === 0 
                        ? null
                        : (
                            <>
                                <p style={{marginLeft: '5px'}}><b>Summary</b></p>
                                <Table className='timecard' responsive bordered hover>
                                    <JsonTable 
                                        data={[ttRoute.routes[0].summary]} 
                                        removeField={
                                            [
                                                'liveTrafficIncidents',
                                                'TravelTimeInSeconds', 
                                                'noTrafficTravelTimeInSeconds',
                                                'trafficDelayInSeconds',
                                                'trafficLengthInMeters',
                                                'travelTimeInSeconds'
                                            ]} 
                                        
                                    />
                                </Table> 
                                <br/>
                                <p style={{marginLeft: '5px'}}><b>Traffic Stats</b></p>
                                <Table className='timecard' responsive bordered hover>
                                    <JsonTable 
                                        data={[ttRoute.routes[0].summary]} 
                                        removeField={
                                            [
                                                'arrivalTime',
                                                'departureTime',
                                                'historicTrafficTravelTimeInSeconds',
                                                'lengthInMeters',
                                            ]} 
                                        
                                    />
                                </Table> 
                                <br/>
                                <p style={{marginLeft: '5px'}}><b>Guidance Instructions</b></p>
                                <Table className='timecard' responsive bordered hover>
                                    <JsonTable 
                                        data={orbInstructions} 
  Orb                              />
                                </Table>
                            </>
                        ) 
                    }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <h4 style={{marginLeft: '5px'}}>
                            Here RouteAPI Meta Data
                        </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                    {
                        hereETA.length === 0
                        ? null
                        : (
                            <>
                                <p style={{marginLeft: '5px'}}><b>Summary</b></p>

                                <Table className='timecard' responsive bordered hover >
                                    <JsonTable data={[hereRoute.routes[0].sections[0].summary]}/>
                                </Table>
                            </>
                        )
                    }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <h4 style={{marginLeft: '5px'}}>
                            Valhalla RouteAPI Meta Data
                        </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                    {
                        hereETA.length === 0
                        ? null
                        : (
                            <>
                                <p style={{marginLeft: '5px'}}><b>Summary</b></p>

                                <Table className='timecard' responsive bordered hover >
                                    <JsonTable data={[valhallaRoute.trip.summary]}/>
                                </Table>
                                <br/>
                                <p style={{marginLeft: '5px'}}><b>Guidance Instructions</b></p>
                                <Table className='timecard' responsive bordered hover >
                                    <JsonTable data={valInstructions} />
                                </Table>
                            </>
                        )
                    }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>       
            
            <br/>
            <br/>
        </div>
    )
}

export default RoutingComparator;