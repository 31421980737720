import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import {Icon} from 'leaflet';

// Define the custom icon
const customIcon = new Icon({
  iconUrl:  'https://img.icons8.com/?size=60&id=19326&format=png&color=000000', // Path to your custom icon image
//   iconSize: [60, 60], // size of the icon
  iconAnchor: [30, 60], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -60] // point from which the popup should open relative to the iconAnchor
});

const CustomMarker = ({ position, ffAddress }) => {
  if (position !== null) {
    return (
      <Marker position={position} icon={customIcon}>
        <Popup>
          {ffAddress}
        </Popup>
      </Marker>
    );
  }
  return null;
};

export default CustomMarker;
