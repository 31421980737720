const HERE_API_KEY='_nZjveYcqp8oNqA0Gz9PRvjTWuEfOLT39XS-83IRTLw';
const TOMTOM_API_KEY='wNwKO5fgWhDOBLlrpOLeXaS6a5iES9Nh';
const BING_API_KEY='';

const MapsConfig = [
        {
            id: 'google',
            name: 'Google Maps',
            url: 'https://mt2.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
            options: { maxZoom: 20, subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], attribution: 'Map data © 2023 Google' },
            active: false, 
            center: [51.505, -0.09],
            zoom: 13
        },
        {
            id: 'tt_gen',
            name: 'TomTom Genesis',
            url: `https://api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=${TOMTOM_API_KEY}`,
            options: { attribution: 'Map data © 2023 TomTom Genesis' },
            active: false, 
            center: [51.505, -0.09],
            zoom: 13
        },
        {
            id: 'tt_orb',
            name: 'TomTom Orbis',
            url: `https://api.tomtom.com/maps/orbis/map-display/tile/{z}/{x}/{y}.png?apiVersion=1&key=${TOMTOM_API_KEY}&style=street-light&tileSize=512&view=Unified&language=en`,
            options: { attribution: 'Map data © 2023 TomTom Orbis' },
            active: false, 
            center: [51.505, -0.09],
            zoom: 13
        },
        {
            id: 'osm',
            name: 'OpenStreetMap',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            options: { attribution: 'Map data © 2023 OpenStreetMap' },
            active: false, 
            center: [51.505, -0.09],
            zoom: 13
        },
        {
            id: 'esri',
            name: 'Esri',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/Basemap/MapServer/tile/{z}/{y}/{x}',
            options: { attribution: 'Map data © 2023 Esri' },
            active: false, 
            center: [51.505, -0.09],
            zoom: 13
        },
        {
            id: 'carto',
            name: 'Carto Voyager',
            url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
            options: { attribution: 'Map data © 2023 Carto Voyager' },
            active: false, 
            center: [51.505, -0.09],
            zoom: 13
        },
        {
            id: 'here',
            name: 'HERE Maps',
            url: 'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=NkEyGwUMIhGY9V1bUTDlr_kyQgNILFMZgAiULvHFLIo',
            options: { subdomains: '1234', base: 'base', attribution: 'Map data © 2023 HERE' },
            active: false,
            center: [51.505, -0.09], 
            zoom: 13
        },
        {
            id: 'bing',
            name: 'Bing Maps',
            url: `https://dev.virtualearth.net/REST/v1/Imagery/Map/Aerial/{z}/{y}/{x}?key=${BING_API_KEY}`,
            options: { attribution: 'Map data © 2023 Microsoft' },
            active: false, 
            center: [51.505, -0.09],
            zoom: 13
        },
        {
            id: 'otm',
            name: 'Open Topo Map',
            url: `https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png`,
            options: { attribution: 'Map data © 2023 Open Topo Map' },
            active: false, 
            center: [51.505, -0.09],
            zoom: 13
        },
        ];

// 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', // OpenStreetMap
// 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', // Mapbox (requires access token)
// 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/Basemap/MapServer/tile/{z}/{y}/{x}', // Esri World Imagery
// 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png', // Carto Voyager

export default MapsConfig;
