import React, { useRef, useEffect, useState, useCallback } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import "leaflet.sync";
import table from 'react-bootstrap';
import CustomMarker from './CustomMarker';
import attributeData from './TomTomGenOSMAFR.json';
import { debounce } from 'lodash';

const Main = ({ mapsData }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [searchDestination, setSearchDestination] = useState('');
  const [data2, setData2] = useState([]);
  const [searchCoords, setSearchCoords] = useState(null);
  const [ffAddress, setFFAddress]= useState(null);
  const [center] = useState([-26.278302, 27.956929]);
  const [zoom] = useState(9);
  const [attributeTableVisible, setAttributeTableVisible] = useState(false); 
  const [viewState, setViewState] = useState({ lat: center[0], lng: center[1], zoom });
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState([]);
  const mapRefs = useRef([]);


  
  const handleSearchChange = (e) => {
    if(e.keyCode === 13) {
      const placename = e.target.value;
      setSearchDestination(placename);
      
      if (placename.length > 5) {
        fetch(`https://api.tomtom.com/search/2/search/${placename}.json?typeahead=true&limit=6&key=wNwKO5fgWhDOBLlrpOLeXaS6a5iES9Nh`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then((data) => {
            setData2(data.results);
            if (data.results.length > 0) {
              const coords = data.results[0].position;
              const { lat, lon } = coords;
              setSearchCoords([lat, lon]);
              setFFAddress(data.results[0].address.freeformAddress);
              setViewState({ lat: lat, lng: lon, zoom: 14 });
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    } 
    else {
      //Make searched location Icon disspear if there's no text in search.
      // comment if the code bugs.
      setSearchCoords(null);
      setFFAddress(null);
      
    }
  };

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const handleToggleTable = () => {
    setAttributeTableVisible(prev => !prev);
  };
const SyncMapEvent = () => {
    const map = useMap();

    const debouncedSetViewState = useRef(debounce((lat, lng, zoom) => {
      setViewState({ lat, lng, zoom });
    }, 100)).current;
  
    useEffect(() => {
      const onMove = () => {
        const center = map.getCenter();
        const zoom = map.getZoom();
        debouncedSetViewState(center.lat, center.lng, zoom);
      };
      
      map.on('move', onMove);
      map.on('zoom', onMove);
  
      return () => {
        map.off('move', onMove);
        map.off('zoom', onMove);
      };
    }, [map, debouncedSetViewState]);
  
    useEffect(() => {
      map.setView([viewState.lat, viewState.lng], viewState.zoom, { animate: true });
    }, [viewState, map]);
  
    return null;
  };
  
  const handleAttrSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const Table = () => {

    const filteredData = attributeData.filter(item =>
            item.CountryName.toLowerCase().includes(searchTerm) ||
            item.Map.toLowerCase().includes(searchTerm) ||
            item.Area.toLowerCase().includes(searchTerm)
          );
    useEffect(() => {
          
          setTableData(searchTerm === ''? attributeData : filteredData);
        }, []);
  
    return (
      <table className='table  table-striped table-hover'>
        <thead>
          <tr>
          <th scope="col"></th>
          <th scope="col">Map</th>
            <th scope="col">Area</th>
            <th scope="col">Country</th>
            <th scope="col">Number of POI</th>
            <th scope="col">Verified Speeds</th>
            <th scope="col">Address Points</th>
            {/* <th scope="col">Latency</th> */}
            <th scope="col">Routing</th>
            <th scope="col">km of Road</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {tableData.map((item,idx) => (
            <tr key={item.idx}>
               <th scope="row"></th>
              <td>{item.Map}</td>
              <td>{item.Area}</td>
              <td>{item.CountryName}</td>
              <td>{item.NumberOfPOI}</td>
              <td>{item.VerifiedSpeeds}</td>
              <td>{item.AddressPoints}</td>
              {/* <td>{item.Latency}</td> */}
              <td>{item.Routing}</td>
              <td>{item.TotalKmOfRoad}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className="toolbar">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button className="toolbar-button btn"
                onClick={() => handleToggle(1)}
                aria-expanded={activeIndex === 1}
                aria-controls="collapseOne"
              > Search
              </button>
            </h5>
          </div>
          <div
            id="collapseOne"
            className={`collapse ${activeIndex === 1 ? 'show' : ''}`}
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body">
              <input className="form-control input_search" list="searchDestList" id="input_search" type="text" placeholder="Search address..." onKeyUp={handleSearchChange} />
              <datalist id="searchDestList">
                {data2.map((searchItem, idx) => (
                  <option key={idx} value={searchItem.address.freeformAddress} />
                ))}		
              </datalist>                
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button className="toolbar-button btn"
                role='link'
                aria-expanded={activeIndex === 2}
                aria-controls="collapseTwo">
                <a href='/routing' target="_self">Routing</a>
              </button>
            </h5>
          </div>
          {/* <div
            id="collapseTwo"
            className={`collapse ${activeIndex === 2 ? 'show' : ''}`}
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div className={`card-body ${activeIndex === 2 ? 'show' : 'hide'}`} aria-controls="collapseTwo">
              <input className="form-control" type="text" placeholder="Enter Origin..." aria-label="default input example" />
              <input className="form-control" type="text" placeholder="Enter Destination..." aria-label=".form-control-sm example" />
            </div>
          </div> */}
        </div>
        <div className="card">
          <div className="card-header" id="headingThree">
            <h5 className="mb-0">
              <button className="toolbar-button btn"
                onClick={() => {handleToggle(3); handleToggleTable()}}
                aria-expanded={activeIndex === 3}
                aria-controls="collapseThree"
              >Attributes
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            className={`collapse ${activeIndex === 3 ? 'show' : ''}`}
            aria-labelledby="headingThree"
            data-parent="#accordion"
          >
          </div>
        </div>
      </div>
      <div className='grid'>
        {mapsData.slice(0, 4).map((mapData, index) => (
          <MapContainer
            className='grid-item'
            key={mapData.id}
            center={center}
            zoom={zoom}
            whenCreated={(mapInstance) => {
              mapRefs.current[index] = mapInstance;
              if (mapRefs.current.length > 1) {
                mapRefs.current.forEach((map, idx) => {
                  map.sync(mapRefs.current[(idx + 1) % mapRefs.current.length]);
                });
              }
            }}
            style={{ height: '400px', width: '100%' }}
          >
            <SyncMapEvent/>
            <TileLayer url={mapData.url} attribution={mapData.options.attribution} style={{ height: '400px', width: '100%' }} />
            <div className='map-title' style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <p3 style={{ height: '0px', width: '100%' }}>{mapData.name}</p3>
            </div>
            {searchCoords && <CustomMarker position={searchCoords} ffAddress={ffAddress} />}
          </MapContainer>
        ))}
      </div>
      <div style={{padding: "20px"}}>
      { attributeTableVisible && <input
        className="form-control"
        type="text"
        placeholder="Search.."
        // value={searchTerm}
        onKeyUp={handleAttrSearch}
      />}
      {attributeTableVisible && <Table id='map-data' />}
      </div>
      
    </>
  );
};

export default Main;
