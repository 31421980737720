import React, { useState, useCallback } from 'react';
import MapGrid from './MapGrid';
import MapsConfig from './MapConfig';
import Navbar from './Navbar';
import Toolbar from './ToolBar';
import Main from './Main';

const App = () => {

  const [mapsData, setMapsData] = useState(MapsConfig);

  return (
    <div className="App">
      <Navbar mapsData={mapsData} setMapsData={setMapsData} />
      {/* <Toolbar searchMyDestination={handleSearchMyDestination}/> */}
      {/* <MapGrid mapsData={mapsData} searchCoords={searchCoords} /> */}
      <Main mapsData={mapsData}/>

      
    </div>
  );
};

export default App;
