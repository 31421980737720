import React, { useRef, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MapGrid = ({ mapsData, syncZoomPan }) => {
  // const gridMaps = mapsData.slice(0, 4);
  // const mapRef = useRef(null);


  // ------
  const mapRefs = useRef([]);

  useEffect(() => {
    if (mapRefs.current.length > 1) {
      const handleZoomPan = (sourceMap) => {
        const zoom = sourceMap.getZoom();
        const center = sourceMap.getCenter();
        mapRefs.current.forEach((map) => {
          if (map && map !== sourceMap) {
            map.setView(center, zoom, { animate: false });
          }
        });
      };

      mapRefs.current.forEach((map) => {
        if (map) {
          map.on('zoom', () => handleZoomPan(map));
          map.on('move', () => handleZoomPan(map));
        }
      });
    }
  }, [mapsData]);



  return (
    <div className='grid'>
      {mapsData.slice(0,4).map((mapData, index) => (
        <MapContainer
          className='grid-item'
          key={index}
          center={[51.505, -0.09]}
          zoom={13}
          whenCreated= {(mapInstance) => mapRefs.current[index] = mapInstance}
          // ref={mapRef}
          style={{ height: '400px', width: '100%' }}
        >          
        <TileLayer url={mapData.url} attribution={mapData.options.attribution} style={{ height: '400px', width: '100%'  }}>
        </TileLayer>
        <div className='map-title' style={{ position:'absolute', top:'10px', right:'10px'}} ><p3 style={{ height: '0px', width: '100%' }}>{mapData.name}</p3>
        </div>
        </MapContainer>
      ))}
    </div>
  );
};

export default MapGrid;


